import Airtable from "airtable";
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

/**
 * setUpObserver - Sets up the IntersectionObserver
 * @return {void}
 */
const setUpObserver = () => {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setTimeout(() => {
                    entry.target.classList.add('lazy-loaded');
                }, 600);
            }
        });
    });

    const lazyItems = document.querySelectorAll('.lazy-item');

    for (const item of lazyItems) {
        observer.observe(item);
    }
}

const setUpAirtable = () => {
    const base = new Airtable({ apiKey: process.env.AIRTABLE_API_KEY }).base('appMpxgFK6tzRRg7u');
    const signupForms = document.querySelectorAll('.signup-form');

    signupForms.forEach((form) => {

        form.addEventListener('submit', (evt) => {

            evt.preventDefault();

            if (validateForm(form)) {
                let btn = form.querySelector('button');

                btn.disabled = true;
                btn.classList.add('processing');

                createRecord(base, 'wmc_signup', formFields)
                    .then(() => {
                        form.reset();
                    })
                    .catch((error) => {
                        Toastify({
                            containerId: 'toast-container',
                            text: 'Something went wrong submitting the form, please try again later.',
                            style: {
                                background: "red",
                            }
                        }).showToast();
                    })
                    .finally(() => {
                        btn.disabled = false;
                        btn.classList.remove('processing');
                    });
            } else {
                btn.disabled = false;
                btn.classList.remove('processing');
            }

        });
    })
};

const createRecord = async (base, table, formFields) => {
    const record = {
        "fields": {
            'email': formFields.email.value,
            'company': formFields.company.value,
            'phone': formFields.phone.value,
        }
    };

    try {
        const createdRecords = await base(table).create([record]);
        Toastify({
            text: 'Thank you for your enquiry. We’ll be in contact soon.',
            gravity: "bottom",
            style: {
                background: "linear-gradient(to right, #00b09b, #96c93d)",
            }
        }).showToast();
        return createdRecords;
    } catch (error) {
        throw error;
    }
}

const validateForm = (form) => {
    const formFields = form.elements;
    const termsCheckbox = formFields.terms;

    // Clear any existing errors
    document.querySelectorAll('.is-invalid').forEach(el => el.classList.remove('is-invalid'));

    // Check required fields
    const requiredFields = ['email', 'company'];

    requiredFields.forEach((field) => {
        const formField = formFields[field];
        if (!formField.value) {
            formField.closest('.form__field').classList.add('is-invalid');
        } else {
            formField.closest('.form__field').classList.remove('is-invalid');
        }
    });

    if (document.querySelector('.is-invalid')) {
        Toastify({
            containerId: 'toast-container',
            text: 'There was an error with the form submission, please check the fields and try again.',
            style: {
                background: "red",
            }
        }).showToast();

        return false;

    } else if (!termsCheckbox.checked) {

        // Check terms checkbox

        termsCheckbox.closest('.form__field').classList.add('is-invalid');
        Toastify({
            containerId: 'toast-container',
            text: 'Please accept the terms and conditions to continue.',
            style: {
                background: "red",
            }
        }).showToast();

        return false;

    }

    return true;
};

const setUpWeb3Form = () => {

    const signupForms = document.querySelectorAll('.signup-form');

    signupForms.forEach((form) => {

        let btn = form.querySelector('button');

        form.addEventListener('submit', function(e) {

            e.preventDefault();

            btn.disabled = true;
            btn.classList.add('processing');

            if (validateForm(form)) {
                const formData = new FormData(form);
                const object = Object.fromEntries(formData);

                // Format object
                object['access_key'] = process.env.WEB3FORMS_KEY;
                object['subject']    = 'New Submission from likewize-mwc.com';
                object['from_name']  = 'Likewize WMC';

                delete object['terms'];

                const json = JSON.stringify(object);

                fetch('https://api.web3forms.com/submit', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                        body: json
                    })
                    .then(async (response) => {
                        let json = await response.json();
                        if (response.status == 200) {
                            Toastify({
                                text: 'Thank you for signing up! We will be in touch soon.',
                                gravity: "bottom",
                                style: {
                                    background: "linear-gradient(to right, #00b09b, #96c93d)",
                                }
                            }).showToast();
                        } else {
                            Toastify({
                                containerId: 'toast-container',
                                text: `There was an error with the form submission: ${json.message}`,
                                style: {
                                    background: "red",
                                }
                            }).showToast();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        Toastify({
                            containerId: 'toast-container',
                            text: `Something went wrong`,
                            style: {
                                background: "red",
                            }
                        }).showToast();
                    })
                    .then(function() {
                        form.reset();
                        btn.disabled = false;
                        btn.classList.remove('processing');
                    });
            } else {
                btn.disabled = false;
                btn.classList.remove('processing');
            }


        });
    });
}

const setUpTerms = () => {
    const buttons = document.querySelectorAll('.js-show-terms');

    buttons.forEach((button) => {
        button.addEventListener('click', (evt) => {

            evt.preventDefault();

            const reveal = button.closest('section').querySelector('.js-terms-hidden-panel');
            reveal.classList.toggle('show');

            if (reveal.classList.contains('show')) {
                reveal.style.height = reveal.scrollHeight + 'px';
            } else {
                reveal.style.height = 0;
            }
        });
    });
}

setUpObserver();
// setUpAirtable();
setUpWeb3Form();
setUpTerms();
